class RouteNames {
     public HOME = "home";
     public PRICES = "prices";
     public LOGIN = "login";
     public SUPPORT = "support";
     public ADMIN_PANEL = "admin_panel.root";
     public AUDIT = "admin_panel.audit";
     public MAIL_REGISTRY = "admin_panel.mail";
     public SUBLIME = "sublime.root";
     public OFFICES = "sublime.offices";
     public EMPLOYEES = "sublime.employments";
     public EMPLOYEE = "sublime.employee";
     public OFFICE = "sublime.office";
     public REPORTS = "sublime.reports";
     public TIMESHEET = "timesheet";
     public SUBLIME_AUTO_ASSIGNMENTS = "sublime.assignments"
     public USER_PROFILE = "userprofile";
     public PROFILE_ACCOUNT = "profile.account";
     public PROFILE_BANK_ACCOUNTS = "profile.bank_accounts"
     public PROFILE_BANK_ACCOUNT = "profile.bank_account"
     public PROFILE_PERSON_DETAILS = "profile.person.details";
     public PROFILE_ADDRESS = "profile.address";
     public PROFILE_HEADQUARTER = "profile.headquarter";
     public PROFILE_BRANCH_LIST = "profile.branches";
     public PROFILE_BRANCH = "profile.branch";
     public PROFILE_ID_CARD = "profile.person.id_card";
     public PROFILE_SHAREHOLDER = "profile.shareholder";
     public PROFILE_MARITAL_STATUS = "profile.marital";
     public PROFILE_COMPANY_DETAILS = "profile.company.details";
     public PROFILE_COMPANY_DOCUMENTS = "profile.company.documents";
     public PROFILE_COMPANY_BANK_STATEMENTS = "profile.company.bank_statements";
     public PROFILE_COMPANY_BUSINESS_TAX_RETURN = "profile.company.business_tax_return";
     public PROFILE_COMPANY_SUMMARY = "profile.company.summary";
     public PROFILE_OTHER_BANK_PRODUCTS = "profile.company.other_bank_products";
     public PROFILE_PERSON_EAD = "profile.person.ead";
     public PROFILE_PERSON_ID = "profile.person.id";
     public PROFILE_PERSON_GREENCARD = "profile.person.greencard";
     public PROFILE_PERSON_USPASSPORT = "profile.person.uspassport";
     public PROFILE_PERSON_SSN = "profile.person.ssn";
     public PROFILE_PERSON_DOCUMENTS = "profile.person.documents";
     public PERSONAL_DATA = "personaldata";
     public DEPENDANTS = "dependants";
     public COMPANIES = "companies";
     public COMPANY = "company";
     public ADMIN = "admin.root";
     public ADMIN_INDIVIDUALS_LIST = "admin.persons";
     public ADMIN_ADMINS_LIST = "admin.admins";
     public ADMIN_COMPANIES_LIST = "admin.companies";
     public TAXATION_INDIVIDUAL = "taxation.individual.root";
     public TAXATION_INDIVIDUAL_ESTIMATED_SUBSCRIPTIONS = "taxation.individual.estimated.subscriptions";
     public TAXATION_INDIVIDUAL_ESTIMATED_SUBSCRIPTION = "taxation.individual.estimated.subscription";
     public TAXATION_INDIVIDUAL_ESTIMATED_OFFER = "taxation.estimated.offer";
     public TAXATION_INDIVIDUAL_ADMIN_LIST = "taxation.individual.admin.list";
     public TAXATION_INDIVIDUAL_CUSTOMER_LIST = "taxation.individual.customer.list";
     public EMAIL_CONFIRMATION = "email.confirmation";
     public TAXATION_BUSINESS = "taxation.business";
     public TAXATION_BUSINESS_USER_QUESTIONNAIRES = "taxation.business.list";
     public TAXATION_BUSINESS_QUESTIONNAIRE = "taxation.business.questionnaire";
     public TAXATION_BUSINESS_ADMIN = "taxation.business.admin";
     public INVITATION = "invitation";
     public RESET_PASSWORD = "resetpassword";
     public LOCATIONS = "locations";

// ---------------------------ARCHIVE--------------------------->
     public ARCHIVE = "archive.root";
     public ARCHIVE_INDIVIDUAL = "archive.individual";
     public ARCHIVE_BUSINESS = "archive.business";
     public ARCHIVE_PAYROLL = "archive.payroll";
     public ARCHIVE_SALES = "archive.sales";
     public ARCHIVE_AUTH = "archive.auth";
     public ARCHIVE_W8BEN = "archive.w8ben";
     public ARCHIVE_W8BEN_INTERNAL = "archive.w8ben.internal";
     public ARCHIVE_W9 = "archive.w9";
     public ARCHIVE_W9_COMPANY_INTERNAL = "archive.w9.company.internal";
     public ARCHIVE_W9_PERSON_INTERNAL = "archive.w9.person.internal";
     public ARCHIVE_W4 = "archive.w4";
     public ARCHIVE_W4_INTERNAL = "archive.w4.internal";
     public ARCHIVE_IT2104 = "archive.it2104";
     public ARCHIVE_IT2104_INTERNAL = "archive.it2104.internal";
     public ARCHIVE_F8822 = "archive.f8822b";
     public ARCHIVE_I9 = "archive.i9";
     public ARCHIVE_I9_INTERNAL = "archive.i9.internal";
     public ARCHIVE_PANDA = "archive.panda";
     public ARCHIVE_SIGNABLE_LIST_NEW = "archive.signable.list.common";
     public ARCHIVE_SIGNABLE_CUSTOM = "archive.signable.custom";
     public ARCHIVE_SIGNABLE_LIST_OWN = "archive.signable.list.own";
     public ARCHIVE_SIGNABLE_OWN = "archive.signable.own";
     public ARCHIVE_EXPIRING = "archive.expiring";
     public ARCHIVE_RECEIPTS_CLIENT = "archive.receipts.client";
     public ARCHIVE_RECEIPTS_ADMIN = "archive.receipts.admin";
     public ARCHIVE_INVOICES = "archive.invoices"
     public ARCHIVE_ADMIN_IRS_LETTERS = "archive.admin_irs_letters";
     public ARCHIVE_IRS_LETTERS = "archive.irs_letters";
     public ARCHIVE_INSURANCE = "archive.insurance.insurance";
     public ARCHIVE_INSURANCE_COMPANIES = "archive.insurance.companies";
     public ARCHIVE_INSURANCE_SETTINGS = "archive.insurance.settings";
     public ARCHIVE_INSURANCE_GENERAL_LIABILITY = "archive.insurance.GENERAL_LIABILITY";
     public ARCHIVE_INSURANCE_PROFESSIONAL_LIABILITY = "archive.insurance.PROFESSIONAL_LIABILITY";
     public ARCHIVE_INSURANCE_WORKERS_COMPENSATION = "archive.insurance.WORKERS_COMPENSATION";
     public ARCHIVE_INSURANCE_DISABILITY = "archive.insurance.DISABILITY";
     public ARCHIVE_PAYMENTS = "archive.payments";
     public ARCHIVE_PAYMENT = "archive.payment";
     public ARCHIVE_REPORTS_P_ST = "archive.reports";
     public ARCHIVE_ANNUAL_REPORTS = "archive.annual";
     public ARCHIVE_STATISTICAL_REPORTS = "archive.statistical";
     public ARCHIVE_PREPARATION_1099_NEC = "archive.preparation.1099nec";
     public ARCHIVE_PREPARATION_1099_NEC_PUBLIC = "archive.preparation.1099nec.public";

     public TAXATION_INDIVIDUAL_QUESTIONNAIRE = "taxation.individual.questionnaire";
     public TAXATION_INDIVIDUAL_QUESTIONNAIRE_PUBLIC = "taxation.individual.questionnaire.public";
     public PAYROLL = "payroll.root";
     public PAYROLL_INDIVIDUAL = "payroll.individual.root";
     public PAYROLL_INDIVIDUAL_PAYSTUBS = "payroll.individual.paystubs";
     public PAYROLL_INDIVIDUAL_FORMS = "payroll.individual.forms.root";
     public PAYROLL_INDIVIDUAL_FORMS_W2 = "payroll.individual.forms.w2";
     public PAYROLL_INDIVIDUAL_FORMS_1099 = "payroll.individual.forms.1099";
     public PAYROLL_INDIVIDUAL_INCOME_TAX_FEDERAL = "payroll.individual.incometax.federal";
     public PAYROLL_INDIVIDUAL_INCOME_TAX_STATE = "payroll.individual.incometax.state";
     public PAYROLL_BUSINESS = "payroll.business.root";
     public PAYROLL_BUSINESS_EMPLOYEES = "payroll.business.employments";
     public PAYROLL_BUSINESS_PAYSTUBS = "payroll.business.paystubs";
     public PAYROLL_BUSINESS_FORMS = "payroll.business.forms.root";
     public PAYROLL_BUSINESS_FORMS_W2 = "payroll.business.forms.w2";
     public PAYROLL_BUSINESS_FORMS_1099 = "payroll.business.forms.1099";
     public PAYROLL_BUSINESS_FORMS_1042S = "payroll.business.forms.1042s";
     public PAYROLL_BUSINESS_FORMS_K1 = "payroll.business.forms.k1";
     public PAYROLL_BUSINESS_DETAILS = "payroll.business.details";
     public PAYROLL_BUSINESS_GENERAL_DETAILS = "payroll.business.general_details"
     public PAYROLL_BUSINESS_REPORTS = "payroll.business.reports.root";
     public PAYROLL_BUSINESS_REPORTS_FEDERAL = "payroll.business.reports.federal";
     public PAYROLL_BUSINESS_REPORTS_REGIONAL = "payroll.business.reports.regional";
     public PUBLIC_FORMS_W8BEN = "public.forms.w8ben";
     public PUBLIC_FORMS_I9 = "public.forms.i9";
     public PUBLIC_FORMS_W9_COMPANY = "public.forms.w9.person";
     public PUBLIC_FORMS_W9_PERSON = "public.forms.w9.company";
     public PUBLIC_FORMS_W4 = "public.forms.w4";
     public PUBLIC_FORMS_IT2104 = "public.forms.it2104";
     public FORM_8822_B = "forms.f8822B"
     public UPLOAD_COMPANIES = "upload.companies";
     public UPLOAD_EMPLOYEES = "upload.employments";
     public SALES_TAX = "salestax.root";
     public SALES_TAX_DOCUMENTS = "salestax.documents";
     public SALES_TAX_PERMITS = "salestax.permits";
     public SALES_TAX_SUBSCRIPTIONS = "salestax.subscriptions";
     public SALES_TAX_SUBSCRIPTION = "salestax.subscription";
     public SALES_TAX_WORKFLOWS = "salestax.workflows";
     public SALES_TAX_WORKFLOWS_ADMIN = "salestax.workflows_admin";
     public SALES_TAX_PUBLIC_WORKFLOW = "salestax.workflow.public";
     public SALES_TAX_WORKFLOW = "salestax.workflow";
     public SALES_TAX_REPORTS = "salestax.report";
     public SALES_TAX_SCHEDULE = "salestax.schedule";
     public REQUESTS = "requests";
     public RCCR = "rccr";
     public CBSR = "cbsr";
     public RCCR_CUSTOMER = "rccr.customer";
     public CCDR_CUSTOMER = "ccdr.customer";
     public CBSR_CUSTOMER = "cbsr.customer";
     public W9CR_CUSTOMER = "w9cr.customer";
     public W9CR_COMPANY = "w9cr.company";
     public W9CR_PERSON = "w9cr.person";
     public W9CR = "w9cr";
     public FRSO_CUSTOMER = "frso.customer";
     public FRSO_CREATE = "frso.create";
     public FRSO = "frso";
     public ITRQ_CUSTOMER = "itrq.customer";
     public ITRQ_BATCH = "itrq.batch";
     public ITRQ = "itrq";
     public CDRQ_CUSTOMER = "cdrq.customer";
     public CDRQ = "cdrq"
     public RER = "rer";
     public RER_EMPLOYEE = "rer.employee";
     public RER_EMPLOYEE_PUBLIC = "rer.employee.public";
     public RER_CUSTOMER = "rer.customer";
     public RIDC = "ridc";
     public RIDC_PUBLIC = "ridc.public";
     public RIDC_PUBLIC_CONTRACTOR = "ridc.public.contractor";
     public IAAR = "iaar";
     public IAAR_PERSON = "iaar.person";
     public IAAR_PUBLIC = "iaar.public";
     public TRDA = "trda";
     public TRDA_PUBLIC = "trda.public";
     public CR = "CR" // custom request

// ---------------------------New Payroll--------------------------->
     public PAYROLL_COMPANY_PROJECT = "payroll.v2.projects"
     public PAYROLL_COMPANY_INDIVIDUAL_CONTRACTOR = "payroll.v2.company.individual_contractor"
     public PAYROLL_COMPANY_BUSINESS_CONTRACTOR = "payroll.v2.company.business_contractor"
     public PAYROLL_COMPANY_EMPLOYEE_DETAILS = "payroll.v2.company.employee_details"
     public PAYROLL_COMPANY_INDIVIDUAL_FOREIGN_CONTRACTOR = "payroll.v2.company.individual_foreign_contractor"
     public PAYROLL_COMPANY_BUSINESS_FOREIGN_CONTRACTOR = "payroll.v2.company.business_foreign_contractor"
     public PAYROLL_CLOSE_PAY_PERIOD = "payroll.v2.close_pay_period"
     public PAYROLL_REPORTS = "payroll.v2.report.root"
     public PAYROLL_REPORTS_BUILDER = "payroll.v2.report.builder"
     public PAYROLL_COMPANY_POLICY = "payroll.v2.company.policy.root";
     public PAYROLL_COMPANY_POLICY_PTO = "payroll.v2.company.policy.pto"
     public PAYROLL_COMPANY_POLICY_HAOP = "payroll.v2.company.policy.haop"
     public PAYROLL_INDIVIDUAL_REQUEST = "payroll.v2.individual.vcr"
     public PAYROLL_INDIVIDUAL_SICK_LEAVES = "payroll.v2.individual.sick_leaves"
     public PAYROLL_INDIVIDUAL_VACATION_REQUEST = "payroll.v2.individual.vcr"
     public PAYROLL_INDIVIDUAL_SICK_LEAVE_REQUEST = "payroll.v2.individual.slr"
     public PAYROLL_COMPANY_POLICY_SICK_LEAVE = "payroll.v2.company.policy.slp";
     public PAYROLL_INDIVIDUAL_POLICY = "payroll.v2.individual.policy.root";
     public PAYROLL_INDIVIDUAL_POLICY_PTO = "payroll.v2.individual.policy.pto"
     public PAYROLL_INDIVIDUAL_POLICY_HAOP = "payroll.v2.individual.policy.haop"
     public PAYROLL_INDIVIDUAL_POLICY_SICK_LEAVE = "payroll.v2.individual.policy.slp";
     public PAYROLL_BUSINESS_VACATIONS = "payroll.v2.company.vacations";
     public PAYROLL_BUSINESS_VACATIONS_REQUESTS = "payroll.v2.company.vacations.requests";
     public PAYROLL_BUSINESS_VACATIONS_LIST = "payroll.v2.company.vacations.list";
     public PAYROLL_BUSINESS_VACATIONS_BALANCE_DETAILS = "payroll.v2.company.vacations.balance_details";
     public PAYROLL_INDIVIDUAL_VACATIONS = "payroll.v2.individual.vacations";
     public PAYROLL_INDIVIDUAL_VACATIONS_REQUESTS = "payroll.v2.individual.vacations.requests";
     public PAYROLL_INDIVIDUAL_VACATIONS_LIST = "payroll.v2.individual.vacations.list";
     public PAYROLL_INDIVIDUAL_VACATIONS_BALANCE_DETAILS = "payroll.v2.individual.vacations.balance_details";
     public PAYROLL_COMPANY_VACATION_LIST = "payroll.v2.company.vacations"
     public PAYROLL_COMPANY_REQUEST = "payroll.v2.company.vcr"
     public PAYROLL_COMPANY_VACATION_REQUEST = "payroll.v2.company.vcr"
     public PAYROLL_COMPANY_SICK_LEAVE_REQUEST = "payroll.v2.company.slr"
     public PAYROLL_COMPANY_SICK_LEAVE_LIST = "payroll.v2.company.sick_leaves"

     public COMPANY_SERVICES = "payroll.v2.employer.services"
     public CONTRACTOR_SERVICE_LOGS = "payroll.v2.contractor.service_log"
     public EMPLOYER_SERVICE_LOGS = "payroll.v2.employer.service_log"
// -------------------------------HR--------------------------------->
     public HR = "hr.root";
     public HR_EMPLOYEES = "hr.employees";
     public HR_CONTRACTORS = "hr.contractors";
     public HR_FOREIGN_CONTRACTORS = "hr.foreign_contractors";
     public HR_REQUESTS = "hr.requests";

// -------------------------------Sign--------------------------------->
     public SIGN_PUBLIC = "sign.public";

// ------------------------------FinCEN-------------------------------->
     public DOCS_FINCEN_BOI = "archive.fincen.boi.root"
}

export default new RouteNames();
