import {IEmployment} from "@/dto/payroll/IEmployment";
import CompanyDTO from "@/dto/company/CompanyDTO";
import AddressDTO from "@/dto/AddressDTO";

export class CreateProjectPayload {
    title: string | null = null
    companyId: number | null = null
}

export class UpdateProjectPayload {
    id: number | null = null
    title: string | null = null
    employmentsId: number[] = []
    managersId: number[] = []
}

export class ProjectDTO {
    id!: number
    title!: string
    company!: CompanyDTO
    address?: AddressDTO
    employments: IEmployment[] = []
    managers: IEmployment[] = []

}