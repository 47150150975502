















import {Vue, Component} from 'vue-property-decorator';
import CreationServiceModal from "@/components/payroll/business/services/CreationServiceModal.vue";
import Application from "@/state/Application";
import ServiceOrchestrator from "@/services/ServiceOrchestrator";
import {ServiceDTO, ServiceFilter} from "@/components/payroll/business/services/ServiceDTO";
import Page from "@/dto/taxation/Page";
import ServiceView from "@/components/payroll/business/services/ServiceView.vue";
import {processError} from "@/utils/ComponentUtils";

@Component({
	components: {ServiceView}
})
export default class ServiceList extends Vue {

	private filter = new ServiceFilter(
		{
			companyId: this.$wss.getCurrent.id
		}
	)

	private page: Page<ServiceDTO> | null = null

	private showModal() {
		this.$modal.show(
			CreationServiceModal,
			{
				callBack: this.loadServices
			}
		);
	}

	mounted() {
		this.loadServices()
	}

	private loadServices(): void {
		Application.startLoading()
		ServiceOrchestrator.getPageByFilter(this.filter).then(
			page => {
				this.page = page.data;
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

}
