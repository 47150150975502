










































































import {Vue, Component, Prop} from 'vue-property-decorator';
import {
	CreateInvoicePayload,
	ServiceRecordDTO,
	ServiceRecordFilter
} from "@/components/payroll/business/services/ServiceDTO";
import {IEmployment} from "@/dto/payroll/IEmployment";
import SelectOption from "@/components/common/SelectOption";
import Application from "@/state/Application";
import ServiceInvoiceOrchestrator from "@/services/ServiceInvoiceOrchestrator";
import {processError} from "@/utils/ComponentUtils";
import ServiceLogOrchestrator from "@/services/ServiceLogOrchestrator";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";

@Component({
	components: {PortalSelect, PortalDate}
})
export default class CreationContractorServiceInvoiceModal extends Vue {
	private selectedContractor: number | null = null;
	private req = new CreateInvoicePayload()
	private logs: Array<ServiceRecordDTO> = [];

	private selectContractor() {
		this.req.contractorsId = [this.selectedContractor!]
		this.loadLogsIfPossible()
	}

	@Prop()
	private contractors!: IEmployment[]

	@Prop()
	private callBack!: () => void;

	public mounted(): void {
		if (this.contractors.length == 1) {
			this.req.contractorsId = [this.contractors[0].id!]
		}
	}

	get contractorOptions(): SelectOption[] {
		return this.contractors.map(it => SelectOption.builder().title(it.employer.name).value(it.id).build());
	}

	private save() {
		Application.startLoading()
		ServiceInvoiceOrchestrator.createInvoice(this.req).then(
			() => {
				Application.stopLoading()
				this.callBack()
			},
			err => processError(err, this)
		)
	}

	private loadLogsIfPossible() {
		if (this.req.startDate && this.req.endDate && this.req.contractorsId.length > 0) {
			Application.startLoading()
			ServiceLogOrchestrator.getAllByFilter(
				new ServiceRecordFilter(
					{
						assigneesId: this.req.contractorsId.length == 0 ? this.contractors.map(it => it.id!) : this.req.contractorsId,
						startDate: this.req.startDate,
						endDate: this.req.endDate,
						active: true
					}
				)
			).then(
				it => {
					this.logs = it.data
					Application.stopLoading()
				},
				err => processError(err, this)
			)
		}
	}

	private subSrtIfNeed(value: string, length = 20) {
		if (value.length > length) {
			return value.substr(0, length) + '...'
		}
		return value
	}
}
