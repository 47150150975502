























import {Vue, Component, Prop} from 'vue-property-decorator';
import {ServiceRecordDTO} from "@/components/payroll/business/services/ServiceDTO";
import moment from "moment/moment";

export type ServiceLogViewSide = "CONTRACTOR" | "EMPLOYER"
type Mode = "SHORT" | "FULL";

@Component({
	computed: {
		moment() {
			return moment
		}
	}
})
export default class ServiceLogView extends Vue {

	@Prop()
	private log!: ServiceRecordDTO

	@Prop()
	private side!: ServiceLogViewSide

	private mode: Mode = "SHORT"
}
