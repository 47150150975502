

































































































import {Component, Prop, VModel, Vue} from "vue-property-decorator";
import SelectOption from "@/components/common/SelectOption";
import {PropType} from "vue";
import PortalInput from "@/components/common/PortalInput.vue";
import {Md5} from "ts-md5";
import {hashCode} from "@/utils/StringUtils";

@Component({
	methods: {
		hashCode(obj: any): string {
			return Md5.hashStr(JSON.stringify(obj))
		},
	},
	components: {PortalInput}
})
export default class PortalSelect extends Vue {
	private isOpen = false;

	private searchableSubString = ""

	@VModel(
		{
			type: [Object, Array, String, Boolean, Number, null] as PropType<any>,
		}
	)
	private select!: any;

	@Prop({default: "select"})
	private id!: string;

	@Prop({default: null})
	private hint!: string | null;

	@Prop({default: false})
	private multiple!: boolean;

	@Prop({default: false})
	private searchable!: boolean

	@Prop({default:function () {return () => {return;};}})
	private change!: () => void

	@Prop({default: "id"})
	private objectKey!: string;

	@Prop()
	private label!: string;

	@Prop({default: ""})
	private validatePattern!: string | Record<string, any>;

	@Prop({default: function () {return []}})
	private options!: Array<SelectOption>;

  @Prop({default: false})
  private disabled!: boolean

	isNotSelected(option: SelectOption): boolean {
		if (this.model) {
			if (this.multiple) {
				return !(this.model as Array<SelectOption>).find(it => Md5.hashStr(JSON.stringify(it)) == Md5.hashStr(JSON.stringify(option)))
			} else {
				return Md5.hashStr(JSON.stringify(this.model.value)) != Md5.hashStr(JSON.stringify(option.value))
			}
		} else {
			return true
		}
	}

	get model(): any {
		if (this.multiple) {
			if (this.select) {
				return this.options.filter(option => !!(this.select as Array<any>).find(it => Md5.hashStr(JSON.stringify(option.value)) == Md5.hashStr(JSON.stringify(it))));
			} else {
				return [];
			}
		} else {
			const res = this.options.find(val => Md5.hashStr(JSON.stringify(val.value)) == Md5.hashStr(JSON.stringify(this.select)))
			return res ? res : null;
		}
	}

	get isNotValid(): boolean {
		const res = this.$validator.errors.items.find(item => Object(item).vmId == Object(this.$validator).id);
		return !!res;
	}


  get isRequired(): boolean{
    return this.validatePattern.includes("required");
  }
	private toggle() {
	  this.isOpen = !this.isOpen
	}

	private click(option: SelectOption): void {
		if (this.multiple) {
			this.$emit('input', [...(this.model as Array<any>), option].map(val => val.value))
		} else {
			this.$emit('input', option.value)
		}
		this.searchableSubString = ""
		this.toggle();
		this.change();
	}

	private flush(val: SelectOption) {
		if (this.multiple) {
			this.$emit('input', (this.model as Array<any>).filter(i => i != val).map(val => val.value))
		} else {
			this.$emit('input', undefined)
		}
		this.change()
		this.searchableSubString = ""
	}

	get qualifiedOptions(): Array<SelectOption> {
		if (this.searchable && this.searchableSubString != "") {
			return this.options.filter(it => JSON.stringify(it).trim()
				.replaceAll("\"title\":", "")
				.replaceAll("\"value\":", "")
				.toLowerCase()
				.indexOf(this.searchableSubString.toLowerCase()) >= 0
			)
		}
		return this.options
	}
}

