import QueryUtils from "@/utils/QueryUtils";
import axios from "axios";
import {Filter} from "@/dto/Pageable";
import Page from "@/dto/taxation/Page";
import {SERVICE_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import {
    CreateServicePayload,
    ServiceDTO,
    UpdateServicePayload
} from "@/components/payroll/business/services/ServiceDTO";

class ServiceOrchestrator {

    public getPageByFilter<T extends Filter>(filter: T) {
        const query = QueryUtils.objectToQueryString(filter);
        return axios.get<Page<ServiceDTO>>(`${SERVICE_ENDPOINT}${query}`, {headers: authHeader()})
    }

    public getAllByFilter<T extends Filter>(filter: T) {
        const query = QueryUtils.objectToQueryString(filter);
        return axios.get<Array<ServiceDTO>>(`${SERVICE_ENDPOINT}/all${query}`, {headers: authHeader()})
    }

    public createService(payload: CreateServicePayload) {
        return axios.post<void>(`${SERVICE_ENDPOINT}`, payload, {headers: authHeader()})
    }

    public updateService(payload: UpdateServicePayload) {
        return axios.put<void>(`${SERVICE_ENDPOINT}`, payload, {headers: authHeader()})
    }

    public disableService(serviceId: number | string, companyId: number | string) {
        return axios.delete<void>(`${SERVICE_ENDPOINT}/${companyId}/${serviceId}`, {headers: authHeader()})
    }

}

export default new ServiceOrchestrator();
