import axios from "axios";
import {SERVICE_INVOICE_ENDPOINT} from "@/constants/endpoints";
import {
    CreateInvoicePayload,
    ServiceInvoice,
    ServiceInvoiceFilter, UpdateInvoicePayload
} from "@/components/payroll/business/services/ServiceDTO";
import authHeader from "@/services/auth-header";
import Page from "@/dto/taxation/Page";
import QueryUtils from "@/utils/QueryUtils";

class ServiceInvoiceOrchestrator {

    public createInvoice(req: CreateInvoicePayload) {
        return axios.post<void>(`${SERVICE_INVOICE_ENDPOINT}`, req, {headers: authHeader()})
    }

    public getPageByFilter(filter: ServiceInvoiceFilter) {
        const query = QueryUtils.objectToQueryString(filter);
        return axios.get<Page<ServiceInvoice>>(`${SERVICE_INVOICE_ENDPOINT}${query}`, {headers: authHeader()})
    }

    public changeStatus(req: UpdateInvoicePayload) {
        return axios.put(`${SERVICE_INVOICE_ENDPOINT}`, req, {headers: authHeader()})
    }

}

export default new ServiceInvoiceOrchestrator()