export default interface Page<T> {
    first: boolean;
    last: boolean;
    empty: boolean;
    size: number;

    number: number;
    totalPages: number;
    content: Array<T>;
}

export const EMPTY_PAGE: Page<any> = {
    first: false,
    last: false,
    size: 0,
    number: 0,
    totalPages: 0,
    content: [],
    empty: true
}