import axios, {AxiosResponse} from 'axios';
import {
    COMPANY_ACCOUNT_ENDPOINT,
    COMPANY_BANK_ACCOUNT,
    COMPANY_BANK_STATEMENTS,
    COMPANY_BATCH_ENDPOINT,
    COMPANY_BUSINESS_TAX_RETURN,
    COMPANY_DETAILS_ENDPOINT,
    COMPANY_ENDPOINT,
    COMPANY_ENDPOINT_ADDRESS,
    COMPANY_ENDPOINT_SHAREHOLDERS, COMPANY_OTHER_BANK_ACCOUNT_ENDPOINT,
    COMPANY_XLSX_ENDPOINT,
} from '@/constants/endpoints'
import authHeader from "@/services/auth-header";
import CompanyDTO from "@/dto/company/CompanyDTO";
import QueryUtils from "@/utils/QueryUtils";
import CollectionUtils from "@/utils/CollectionUtils";
import CompanyFilterDTO from "@/dto/company/CompanyFilterDTO";
import Pageable from "@/dto/Pageable";
import AddressDTO from "@/dto/AddressDTO";
import UpdateAddressRequest from "@/dto/UpdateAddressRequest";
import ShareholderDTO from "@/dto/company/ShareholderDTO";
import CreateShareholderRequest from "@/dto/company/CreateShareholderRequest";
import CompanyDetailsDTO, {CompanyDocumentsDTO, UploadCompanyDocumentsPayload} from "@/dto/company/CompanyDetailsDTO";
import UpdateCompanyDetailsRequest from "@/dto/company/UpdateCompanyDetailsRequest";
import {BankAccountDTO_OLD} from "@/dto/BankDetailsDTO_OLD";
import UpdateBankAccountRequest from "@/dto/UpdateBankAccountRequest";
import UploadBankStatementsRequest from "@/dto/UploadBankStatementsRequest";
import BankStatementsFilter from "@/dto/BankStatementsFilter";
import BankStatementDTO from "@/dto/BankStatementDTO";
import BusinessTaxReturnDTO from "@/dto/taxation/business/BusinessTaxReturnDTO";
import BusinessTaxReturnFilter from "@/dto/taxation/business/BusinessTaxReturnFilter";
import UploadBusinessTaxationRequest from "@/dto/taxation/business/UploadBusinessTaxationRequest";
import CreateCompanyRequestDTO from "@/dto/company/CreateCompanyRequestDTO";
import MoveCustomerOnAnotherAccountRequest from "@/dto/auth/MoveCustomerOnAnotherAccountRequest";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import BankAccountDTO from "@/dto/BankAccountDTO";
import OtherCompanyBankProductDTO from "@/dto/company/OtherCompanyBankProductDTO";
import BranchDto, {Branch, CreateBranchPayload, UpdateBranchPayload} from "../components/profile/branches/BranchDtos";
import Workspaces from "@/state/Workspaces";
import CreateReserveDocument from "@/components/profile/company/CreateReserveDocument";
import {CreateProjectPayload, ProjectDTO, UpdateProjectPayload} from "@/dto/payroll/ProjectDTOs";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import PersonDTO from "@/dto/person/PersonDTO";


class CompanyService {

    getById(id: number) {
        return axios.get<CompanyDTO>(`${COMPANY_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    getAll() {
        return axios.get<Array<CompanyDTO>>(`${COMPANY_ENDPOINT}`, {headers: authHeader()});
    }

    batchUpload(fileId: number){
        return axios.post(`${COMPANY_BATCH_ENDPOINT}/${fileId}`, {}, {headers: authHeader()});
    }

    getAddressById(id: number) {
        return axios.get<AddressDTO>(`${COMPANY_ENDPOINT}/${id}/address`, {headers: authHeader()});
    }

    getByFilter(filter: CompanyFilterDTO) {
            const queryString = QueryUtils.objectToQueryString(filter)
            return axios.get<Pageable<CompanyDTO>>(`${COMPANY_ENDPOINT}${queryString}`, {headers: authHeader()});

    }

    saveAddress(request: UpdateAddressRequest) {
        return axios.put(`${COMPANY_ENDPOINT_ADDRESS}`, request, {headers: authHeader()})
    }

    getAllShareHolderByCompanyId(id: number) {
        return axios.get<Array<ShareholderDTO>>(`${COMPANY_ENDPOINT}/${id}/shareholders`, {headers: authHeader()});
    }

    saveShareholders(req: CreateShareholderRequest) {
        return axios.post(`${COMPANY_ENDPOINT_SHAREHOLDERS}`, req, {headers: authHeader()});
    }

    getDetailsById(id: number) {
        return axios.get<CompanyDetailsDTO>(`${COMPANY_DETAILS_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    updateCompanyDetails(req: UpdateCompanyDetailsRequest) {
        return axios.put(`${COMPANY_DETAILS_ENDPOINT}`, req, {headers: authHeader()});
    }

    getBankDetails(id: number) {
        return axios.get<BankAccountDTO_OLD>(`${COMPANY_ENDPOINT}/${id}/bank_account`, {headers: authHeader()})
    }

    updateBankDetails(req: UpdateBankAccountRequest) {
        return axios.put(`${COMPANY_BANK_ACCOUNT}`, req, {headers: authHeader()})
    }

    uploadBankStatements(req: UploadBankStatementsRequest) {
        return axios.post(`${COMPANY_BANK_STATEMENTS}`, req, {headers: authHeader()})
    }

    getAllStatementsYears(id: number) {
        return axios.get<Array<string>>(`${COMPANY_ENDPOINT}/${id}/bank_statements/periods`, {headers: authHeader()})
    }

    getAllBankStatementsByActualYear(filter: BankStatementsFilter) {
        const queryString = QueryUtils.toQueryString(
            CollectionUtils.createPathParamsMap(
                ['companyId', filter.companyId],
                ['year', filter.year]));
        return axios.get<Array<BankStatementDTO>>(`${COMPANY_BANK_STATEMENTS}${queryString}`, {headers: authHeader()})
    }

    getAllBusinessTaxReturns(filter: BusinessTaxReturnFilter) {
        const queryString = QueryUtils.toQueryString(
            CollectionUtils.createPathParamsMap(
                ['companyId', filter.companyId],
                ['period', filter.period]));
        return axios.get<Array<BusinessTaxReturnDTO>>(`${COMPANY_BUSINESS_TAX_RETURN}${queryString}`, {headers: authHeader()})
    }

    getAllBTRPeriods(id: number) {
        return axios.get<Array<string>>(`${COMPANY_ENDPOINT}/${id}/business_tax_returns/periods`, {headers: authHeader()})
    }

    uploadBusinessTaxReturn(req: UploadBusinessTaxationRequest) {
        return axios.post(`${COMPANY_BUSINESS_TAX_RETURN}`, req, {headers: authHeader()});
    }

    registerCustomer(req: CreateCompanyRequestDTO) {
        return axios.post(`${COMPANY_ENDPOINT}`, req, {headers: authHeader()})
    }

    registerCompanyWithExistEmail(req: CreateCompanyRequestDTO) {
        return axios.put(`${COMPANY_ENDPOINT}`, req, {headers: authHeader()})
    }

    moveToAnotherAccount(req: MoveCustomerOnAnotherAccountRequest) {
        return axios.put(`${COMPANY_ACCOUNT_ENDPOINT}`, req, {headers: authHeader()});
    }

    deleteBusinessTaxReturnById(id: number) {
        return axios.delete(`${COMPANY_BUSINESS_TAX_RETURN}/${id}`, {headers: authHeader()});
    }

    exportToExcel(filter: CompanyFilterDTO): Promise<AxiosResponse<FileMetaDTO>>{
        const queryString = QueryUtils.buildQueryString(
            ['companyName', filter.companyName],
            ['pageNumber', filter.pageNumber],
            ['email', filter.email],
            ['ein', filter.ein],
            ['orderField', filter.orderField],
            ['orderDirection', filter.orderDirection]);
        return axios.get<FileMetaDTO>(`${COMPANY_XLSX_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    getBankAccounts(companyId: number, onlyVerified = false): Promise<AxiosResponse<Array<BankAccountDTO>>>{
        const queryString = QueryUtils.buildQueryString(
            ["onlyVerified", onlyVerified]
        )
        return axios.get<Array<BankAccountDTO>>(`${COMPANY_ENDPOINT}/${companyId}/bank_accounts${queryString}`, {headers: authHeader()});
    }

    addBankAccount(account: BankAccountDTO) {
        return axios.post(`${COMPANY_ENDPOINT}/${account.companyId}/bank_accounts`, account, {headers: authHeader()})
    }

    getBankAccountById(id: number | null, companyId: number): Promise<AxiosResponse<BankAccountDTO>>  {
        return axios.get<BankAccountDTO>(`${COMPANY_ENDPOINT}/${companyId}/bank_accounts/${id}`, {headers: authHeader()})
    }

    updateBankAccount(account: BankAccountDTO): Promise<AxiosResponse<void>> {
        return axios.put(`${COMPANY_ENDPOINT}/${account.companyId}/bank_accounts`, account, {headers: authHeader()})
    }

    verify(bankAccountId: number | null, verified: boolean) {
        return axios.put<void>(`${COMPANY_ENDPOINT}/bank_accounts/verify`, {isVerified: verified, bankAccountId: bankAccountId}, {headers: authHeader()})
    }

    deleteAchForm(id: number | null) {
        return axios.delete<void>(`${COMPANY_ENDPOINT}/bank_accounts/${id}/ach`, {headers:authHeader()})
    }

    deleteBankAccount(id: number | null) {
        return axios.delete(`${COMPANY_ENDPOINT}/bank_accounts/${id}`, {headers: authHeader()})
    }

    saveOtherBankAccount(account: OtherCompanyBankProductDTO){
        return axios.put(COMPANY_OTHER_BANK_ACCOUNT_ENDPOINT, account, {headers: authHeader()});
    }

    getOtherBankAccounts(companyId: number){
        return axios.get<Array<OtherCompanyBankProductDTO>>(`${COMPANY_OTHER_BANK_ACCOUNT_ENDPOINT}/${companyId}`, {headers: authHeader()});
    }

    getIsClientStatus(id: number) {
        return axios.get<boolean>(`${COMPANY_ENDPOINT}/${id}/isClient`, {headers: authHeader()})
    }

    setIsClientStatus(id: number, isClient: boolean) {
        return axios.put<void>(`${COMPANY_ENDPOINT}/isClient`, {id: id, isClient: isClient}, {headers: authHeader()})
    }

    getBranches(companyId: number): Promise<AxiosResponse<Array<BranchDto>>> {
        return axios.get<Array<BranchDto>>(`${COMPANY_ENDPOINT}/${companyId}/branches`, {headers: authHeader()})
    }

    createBranch(req: CreateBranchPayload) {
        return axios.post<Array<BranchDto>>(`${COMPANY_ENDPOINT}/${Workspaces.getCurrent.id}/branches`, req, {headers: authHeader()})
    }

    loadBranchById<T extends Branch>(id: string) {
        return axios.get<T>(`${COMPANY_ENDPOINT}/${Workspaces.getCurrent.id}/branches/${id}`, {headers: authHeader()})
    }

    updateBranch<T extends Branch>(req: T) {
        return axios.put<void>(`${COMPANY_ENDPOINT}/${Workspaces.getCurrent.id}/branches`, req, {headers: authHeader()})
    }

    getHeadQuarter(companyId: number): Promise<AxiosResponse<BranchDto>> {
        return axios.get<BranchDto>(`${COMPANY_ENDPOINT}/${companyId}/headquarter`, {headers: authHeader()})
    }

    removeProject(id: number, companyId = Workspaces.getCurrent.id) {
        return axios.delete<void>(`${COMPANY_ENDPOINT}/${companyId}/projects/${id}`, {headers: authHeader()})
    }

    createProject(createReq: CreateProjectPayload) {
        return axios.post<void>(`${COMPANY_ENDPOINT}/projects`, createReq, {headers: authHeader()})
    }

    getProjectsByCompanyId(companyId = Workspaces.getCurrent.id): Promise<AxiosResponse<Array<ProjectDTO>>> {
        return axios.get<Array<ProjectDTO>>(`${COMPANY_ENDPOINT}/${companyId}/projects`, {headers: authHeader()})
    }

    updateProject(updateReq: UpdateProjectPayload, companyId = Workspaces.getCurrent.id) {
        return axios.put<void>(`${COMPANY_ENDPOINT}/${companyId}/projects`, updateReq, {headers: authHeader()})
    }

    getOfficer(companyId: number){
        return axios.get<PersonDTO>(`${COMPANY_ENDPOINT}/${companyId}/officer`, {headers: authHeader()});
    }

    getDocuments(companyId: number = Workspaces.getCurrent.id) {
        return axios.get<CompanyDocumentsDTO>(`${COMPANY_ENDPOINT}/${companyId}/documents`, {headers: authHeader()})
    }

    uploadDocuments(docs: UploadCompanyDocumentsPayload) {
        return axios.put(`${COMPANY_ENDPOINT}/documents`, docs, {headers: authHeader()})
    }

    deleteShareholder(shareholderId: number) {
        return axios.delete(`${COMPANY_ENDPOINT}/shareholders/${shareholderId}`, {headers: authHeader()})
    }
}

export default new CompanyService();
