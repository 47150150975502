






























































import {Vue, Component, Prop} from 'vue-property-decorator';
import {ServiceDTO, UpdateServicePayload} from "@/components/payroll/business/services/ServiceDTO";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import ServiceOrchestrator from "@/services/ServiceOrchestrator";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import PortalInput from "@/components/common/PortalInput.vue";

export type Mode = "view" | "edit"

@Component({
	components: {PortalInput}
})
export default class ServiceView extends Vue {

	private mode: Mode = "view";

	private req: UpdateServicePayload = new UpdateServicePayload();

	@Prop()
	private service!: ServiceDTO

	toEdit() {
		this.req = new UpdateServicePayload(
			{
				serviceId: this.service.id,
				companyId: this.$wss.getCurrent.id,
				name: this.service.name,
				description: this.service.description,
				price: this.service.price
			}
		)
		this.mode = "edit";
	}

	showDisableModal() {
		this.$modal.show(
			DeleteConfirmation,
			{
				targetName: "service",
				operationName: "disable",
				onDelete: this.disable
			}
		)
	}

	save() {
		Application.startLoading()
		ServiceOrchestrator.updateService(this.req).then(
			() => {
				this.service.name = this.req.name!
				this.service.price = this.req.price!
				this.mode = "view"
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	disable() {
		Application.startLoading()
		ServiceOrchestrator.disableService(this.service.id, this.service.company.id).then(
			() => {
				Application.stopLoading()
				this.mode = "view"
				this.service.active = false
			},
			err => processError(err, this)
		)
	}

}
