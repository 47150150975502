



































































































import {Component, Vue} from 'vue-property-decorator';
import {
	ServiceInvoice,
	ServiceInvoiceFilter,
	ServiceRecordDTO,
	ServiceRecordFilter
} from "@/components/payroll/business/services/ServiceDTO";
import Application from "@/state/Application";
import ServiceRecordOrchestrator from "@/services/ServiceLogOrchestrator";
import Page, {EMPTY_PAGE} from "@/dto/taxation/Page";
import {processError} from "@/utils/ComponentUtils";
import CreationContractorServiceLogModal
	from "@/components/payroll/business/services/CreationContractorServiceLogModal.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";
import EmploymentService from "@/services/EmploymentService";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import ServiceLogView from "@/components/payroll/business/services/ServiceLogView.vue";
import ServiceInvoiceItem from "@/components/payroll/business/services/ServiceInvoiceItem.vue";
import ServiceInvoiceOrchestrator from "@/services/ServiceInvoiceOrchestrator";
import CreationServiceInvoiceModal from "@/components/payroll/business/services/CreationServiceInvoiceModal.vue";
import CreationContractorServiceInvoiceModal
	from "@/components/payroll/business/services/CreationContractorServiceInvoiceModal.vue";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import {subSrtIfNeed} from "../../../../utils/StringUtils";
import InvoiceDTO from "@/dto/InvoiceDTO";

@Component({
	methods: {subSrtIfNeed},
	components: {ServiceInvoiceItem, ServiceLogView, PaginationComponent}
})
export default class ContractorServiceLogList extends Vue {

	private records: Page<ServiceRecordDTO> = EMPTY_PAGE;
	private invoices: Page<ServiceInvoice> = EMPTY_PAGE
	private contracts: Array<IEmployment> = []

	private filter = new ServiceRecordFilter()
	private invoiceFilter = new ServiceInvoiceFilter(
		{
			size: 5
		}
	)

	mounted() {
		Application.startLoading()
		this.resolveContracts()
			.then(() => this.loadServiceRecords())
			.then(() => this.loadInvoices())
			.finally(() => Application.stopLoading())
	}

	private resolveContracts() {
		return EmploymentService.getAllByFilter(new EmploymentFilter(
			{
				detailsId: this.$wss.getCurrent.id,
				status: [EmploymentStatus.ACTIVE, EmploymentStatus.NEW, EmploymentStatus.PENDING_FOR_DOCUMENTS],
				types: ["CONTRACTOR", "FOREIGN"]
			}
		)).then(
			res => {
				this.contracts = res.data
				this.filter.assigneesId = [...this.contracts.map(it => it.id!)]
				this.invoiceFilter.contractorsId = this.filter.assigneesId
			},
			err => processError(err, this)
		)
	}

	private loadServiceRecords() {
		if (this.filter.assigneesId.length == 0) return Promise.reject()
		return ServiceRecordOrchestrator.getPageByFilter(this.filter).then(
			res => {this.records = res.data},
			err => processError(err, this)
		)
	}

	private showCreationModal() {
		this.$modal.show(
			CreationContractorServiceLogModal,
			{
				callback: this.loadServiceRecords
			},
			{
				classes: ["overflow-visible"]
			}
		)
	}

	private loadInvoices() {
		return ServiceInvoiceOrchestrator.getPageByFilter(this.invoiceFilter).then(
			res => {
				const invs: Array<ServiceInvoice> = [...this.invoices.content, ...res.data.content]
				this.invoices = res.data
				this.invoices.content = invs
			},
			err => processError(err, this)
		)
	}

	private loadNextPage(page: number) {
		this.filter.page = page
		this.loadServiceRecords()
	}

	private close(record: ServiceRecordDTO) {
		(this.$refs[`popover_${record.id}`] as Array<Vue>)[0].$emit("close")
	}

	private loadNextInvoicePage() {
		this.invoiceFilter.page++
		Application.startLoading()
		this.loadInvoices()
			.finally(() => Application.stopLoading())
	}

	private showInvoiceCreationModal() {
		this.$modal.show(
			CreationContractorServiceInvoiceModal,
			{
				contractors: this.contracts,
				callBack: () => {
					this.$modal.hideAll()
					Application.startLoading()
					this.loadInvoices().finally(Application.stopLoading)
				}
			}
		)
	}

	private disable(record: ServiceRecordDTO) {
		this.close(record)
		this.$modal.show(
			DeleteConfirmation,
			{
				onDelete: () => {
					Application.startLoading()
					ServiceRecordOrchestrator.disableLog(record.id, record.assignee.id).then(
						() => {
							return Promise.all(
								[
									this.loadServiceRecords(),
									this.loadInvoices()
								]
							)
						},
						err => processError(err, this)
					).finally(Application.stopLoading)
				},
				targetName: `"${record.name}"`,
				operationName: 'disable'
			}
		)
	}

}
