import {EmploymentType, ContractType} from "@/constants/EmploymentType";
import CompanyDTO from "@/dto/company/CompanyDTO";
import {IEmployment} from "@/dto/payroll/IEmployment";
import SignableDocumentDTO from "@/dto/files/SignableDocumentDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import PersonDTO from "@/dto/person/PersonDTO";

export class CreatePTOPolicyPayload {
    companyId: number | null = null;
    eligibilityPeriod: number | null = 183 // days
    vacationIntervalTotal: number | null = null // hours
    vacationIntervalMin: number | null = 8 // hours
    vacationNoticePeriod: number | null = 14 // days
    employmentType: EmploymentType | null = null
    carryover: number | null = null // hours
    vacationPayout = false
    branchId: number | null = null
    applicableFor: ContractType | null = null;
    applicableState: string | null = "NY";
    officerTitle: string | null = "Officer";
    title = "PTO (Paid Time Off) Policy (NY)";

    constructor(init?: Partial<CreatePTOPolicyPayload>) {
        Object.assign(this, init)
    }
}

export class UpdatePTOPolicyPayload {
    policyId: number | null = null;
    eligibilityPeriod: number | null = null // days
    vacationIntervalTotal: number | null = null // hours
    vacationIntervalMin: number | null = null // hours
    vacationNoticePeriod: number | null = null // days
    carryover: number | null = null // hours
    vacationPayout = false
    applicableFor: ContractType | null = null;
    applicableState: string | null = "NY";
    title: string | null = null;
    officerTitle: string | null = null;

    constructor(init ?: Partial<UpdatePTOPolicyPayload>) {
        Object.assign(this, init)
    }
}

export class PTOPolicy {
    id!: number
    company!: CompanyDTO
    title!: string
    officer!: PersonDTO
    eligibilityPeriod!: number
    vacationIntervalTotal!: number
    vacationIntervalMin!: number
    vacationNoticePeriod!: number
    carryover!: number
    vacationPayout!: boolean
    applicableFor: ContractType | null = null;
    isActive!: boolean
    applicableState: string | null = null;
    officerTitle!: string;
    createDate!: Date;
    signatures: Array<PolicySignature> = [];
    policyPublicId!: string;
    branch!: string
    approvedPolicy!: FileMetaDTO
}

export class PolicySignature {
    id!: number
    employment!: IEmployment;
    document: SignableDocumentDTO | null = null
}

export class PTOSignature {
    id!: number
    employment!: IEmployment;
    policy!: PTOPolicy;
    document?: FileMetaDTO
    publicId!: string
}

export class PTOPolicyFilter {
    companyId: number | null = null;
    applicableFor: ContractType | null = null;
    applicableState: string | null = null;
    signed: boolean | null = null;
    pageNumber = 0;
    pageSize = 20
    isActive: boolean | null = null

    constructor(init?: Partial<PTOPolicyFilter>) {
        Object.assign(this, init)
    }
}