



























import {Vue, Component, Model, Prop, Watch} from "vue-property-decorator";
import {debounce} from 'typescript-debounce-decorator';
import PersonDTO from "@/dto/person/PersonDTO";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";
import AdminService from "@/services/AdminService";
import SublimeEmployeeFilterDTO from "@/dto/sublime/employee/SublimeEmployeeFilterDTO";
import {processError} from "@/utils/ComponentUtils";

@Component({})
export default class SublimeSearch extends Vue {

  @Model('change', {default: null})
  private selectedSublime!: SublimeEmployeeDTO | PersonDTO | null;

  @Prop({default: "sublime"})
  private name!: string

  @Prop({default: ''})
  private label!: string;

  @Prop({default: ''})
  private placeholder!: string;

  private filter = new SublimeEmployeeFilterDTO({pageSize: 8});

  private sublimeEmployeeDTOS: Array<SublimeEmployeeDTO> = [];

  private showList = false;

  mounted() {
    if (this.selectedSublime) {
      this.filter.fullNameFilter = this.selectedSublime.firstName + ' ' + this.selectedSublime.lastName;
    }
  }

  @debounce(500)
  search() {
    if (!this.filter.fullNameFilter) {
      this.$emit('change', {id: null});
    }
    AdminService.getEmployees(this.filter).then(
        ok => {
          this.sublimeEmployeeDTOS = ok.data.data;
          this.showList = true;
        },
        err => {
          processError(err, this);
        }
    )
  }

  selectSublime(s: SublimeEmployeeDTO) {
    this.showList = false;
    this.filter.fullNameFilter = s.firstName + ' ' + s.lastName;
    this.$emit('change', s);
  }

  filterByCurrentName(): void {
    this.filter.fullNameFilter = this.$wss.getCurrent.name
    console.log(this.filter.fullNameFilter)
  }

}
