import {Filter} from "@/dto/Pageable";
import QueryUtils from "@/utils/QueryUtils";
import axios from "axios";
import Page from "@/dto/taxation/Page";
import {
    CreateServiceRecordPayload,
    ServiceRecordDTO,
    UpdateServiceRecordPayload
} from "@/components/payroll/business/services/ServiceDTO";
import {SERVICE_LOG_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";

class ServiceLogOrchestrator {

    public getPageByFilter<T extends Filter>(filter: T) {
        const query = QueryUtils.objectToQueryString(filter);
        return axios.get<Page<ServiceRecordDTO>>(`${SERVICE_LOG_ENDPOINT}${query}`, {headers: authHeader()})
    }

    public getAllByFilter<T extends Filter>(filter: T) {
        const query = QueryUtils.objectToQueryString(filter);
        return axios.get<Array<ServiceRecordDTO>>(`${SERVICE_LOG_ENDPOINT}/all${query}`, {headers: authHeader()})
    }

    public createLog(payload: CreateServiceRecordPayload) {
        return axios.post<void>(`${SERVICE_LOG_ENDPOINT}`, payload, {headers: authHeader()})
    }

    public updateLog(payload: UpdateServiceRecordPayload) {
        return axios.put<void>(`${SERVICE_LOG_ENDPOINT}`, payload, {headers: authHeader()})
    }

    public disableLog(serviceId: number | string | null, companyId: number | string | null) {
        return axios.delete<void>(`${SERVICE_LOG_ENDPOINT}/${companyId}/${serviceId}`, {headers: authHeader()})
    }

}

export default new ServiceLogOrchestrator()