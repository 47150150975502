













































import {Vue, Component, Prop} from 'vue-property-decorator';
import {CreateServicePayload} from "@/components/payroll/business/services/ServiceDTO";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {ifValid, processError} from "@/utils/ComponentUtils";
import Application from "@/state/Application";
import ServiceOrchestrator from "@/services/ServiceOrchestrator";

@Component({
	components: {PortalCheckbox, PortalInput}
})
export default class CreationServiceModal extends Vue {

	@Prop()
	private callBack!: () => void;

	private req = new CreateServicePayload(
		{
			companyId: this.$wss.getCurrent.id
		}
	)

	private save() {
		ifValid(this, () => {
			Application.startLoading();
			ServiceOrchestrator.createService(this.req).then(
				() => {
					Application.stopLoading();
					this.callBack();
					this.$modal.hideAll()
				},
				err => processError(err, this)
			)
		})
	}

}
