










































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {CreateSickLeavePolicyPayload} from "@/dto/payroll/policies/SickLeavePolicyDTO";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {ContractType, contractTypeOptions, EMPLOYMENT_TYPE_OPTIONS, EmploymentType} from "@/constants/EmploymentType";
import SelectOption from "@/components/common/SelectOption";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {ifValid, processError} from "@/utils/ComponentUtils";
import PolicyService from "@/services/payroll/PolicyService";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";

export enum CompanyType {
	MICRO = "MICRO",
	MEDIUM = "MEDIUM",
	LARGE = "LARGE"
}

export enum RevenueType {
	LESS_MILLION = "LESS_MILLION",
	MORE_MILLION = "MORE_MILLION",
}

@Component({
	computed: {
		SelectOption() {
			return SelectOption
		},
		EMPLOYMENT_TYPE_OPTIONS() {
			return EMPLOYMENT_TYPE_OPTIONS
		},
		CompanyType() {
			return CompanyType
		},
		RevenueType() {
			return RevenueType
		}
	},
	components: {BTPortalCollapse, PortalRadio, PortalSelect, PortalInput, PortalCheckbox}
})
export default class SickLeavePolicyCreationModal extends Vue {

	private companyType: CompanyType | null = null;
	private revenueType: RevenueType | null = null;

	private req = new CreateSickLeavePolicyPayload(
		{
			companyId: this.$wss.getCurrent.id
		}
	)

	@Prop()
	private callback!: () => void

	private create() {
		ifValid(this, () => {
			Application.startLoading();
			PolicyService.createSickLeavePolicy(this.req).then(
				() => {
					this.$modal.hideAll();
					Application.stopLoading();
					this.callback();
				},
				err => processError(err, this)
			)
		})
	}

	COMPANY_OPTIONS = [
		SelectOption.builder().title("1 - 4").value(CompanyType.MICRO).build(),
		SelectOption.builder().title("5 - 99").value(CompanyType.MEDIUM).build(),
		SelectOption.builder().title("100 and more").value(CompanyType.LARGE).build(),
	]

	REVENUE_OPTIONS = [
		SelectOption.builder().title("$0 - $999.999").value(RevenueType.LESS_MILLION).build(),
		SelectOption.builder().title("$1.000.000 and more").value(RevenueType.MORE_MILLION).build(),
	]

	private get contractType(): Array<SelectOption> {
		const options: Array<SelectOption> = []
		options.push(SelectOption.builder().title("All").value(null).build())
		options.push(...contractTypeOptions([ContractType.FIXED_TERM]))
		return options;
	}

	private get employmentOptions(): SelectOption[] {
		return [
			SelectOption.builder().title("All").value(null).build(),
			SelectOption.builder().title("Employee").value(EmploymentType.STAFF).build(),
			SelectOption.builder().title("Foreign employee").value(EmploymentType.FOREIGN).build()
		]
	}

	@Watch("companyType")
	@Watch("revenueType")
	public constraints() {
		switch (this.companyType) {
			case CompanyType.MICRO:
				switch (this.revenueType) {
					case RevenueType.LESS_MILLION:
						this.req.notPayInterval = 40
						this.req.payInterval = 0
						break;
					case RevenueType.MORE_MILLION:
						this.req.notPayInterval = 0
						this.req.payInterval = 40
						break;
				}
				break
			case CompanyType.MEDIUM:
				this.req.notPayInterval = 0
				this.req.payInterval = 40
				break
			case CompanyType.LARGE:
				this.req.notPayInterval = 0
				this.req.payInterval = 56
				break
			default:
				break
		}
	}

	get payIntervalConstraints(): string {
		switch (this.companyType) {
			case CompanyType.MICRO:
				switch (this.revenueType) {
					case RevenueType.LESS_MILLION: return "min_value:0"
					default: return "min_value:40"
				}
			case CompanyType.MEDIUM: return "min_value:40"
			default: return "min_value:56"
		}
	}

	get notPayIntervalConstraints(): string {
		switch (this.companyType) {
			case CompanyType.MICRO:
				switch (this.revenueType) {
					case RevenueType.LESS_MILLION:
						return this.req.payInterval != null && this.req.payInterval >= 40 ? "min_value:0" : "min_value:40"
					default: return "min_value:0"
				}
			case CompanyType.MEDIUM: return "min_value:0"
			default: return "min_value:0"
		}
	}


}

