export function prettyEnum(enumItem: any): string {
    if (!enumItem) return ""
    return enumItem.substring(0, 1) + enumItem.substring(1).toLowerCase().replaceAll("_", " ")
}

export function subSrtIfNeed(value: string, length = 20): string {
    if (value.length > length) {
        return value.substr(0, length) + '...'
    }
    return value
}

export function hashCode(value: string): number {
    let hash = 0;
    for (let i = 0; i < value.length; i++) {
        const char = value.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash |= 0;
    }
    return hash;
}


class StringUtils {

    hashCode(value: string): number {
        return hashCode(value);
    }

    enumToFormattedSentence(enumValue: any): string {
        const enumString = enumValue as string;
        const words = enumString.split('_');
        const formattedWords = words.map((word, index) => {
            const lowerCaseWord = word.toLowerCase();
            return index === 0
                ? lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1)
                : lowerCaseWord;
        });
        return formattedWords.join(' ');
    }

}

export default new StringUtils();