







































import {Vue, Component, Prop} from 'vue-property-decorator';
import {ServiceInvoice, UpdateInvoicePayload} from "@/components/payroll/business/services/ServiceDTO";
import moment from "moment/moment";
import Application from "@/state/Application";
import ServiceInvoiceOrchestrator from "@/services/ServiceInvoiceOrchestrator";
import {processError} from "@/utils/ComponentUtils";
import {prettyEnum} from "@/utils/StringUtils";

export type ServiceInvoiceSide = 'EMPLOYER' | 'CONTRACTOR'

@Component({
	methods: {
		prettyEnum(val: string) {
			return prettyEnum(val)
		}
	},
	computed: {
		moment() {
			return moment
		}
	}
})
export default class ServiceInvoiceItem extends Vue {

	private open = false;

	@Prop()
	private invoice!: ServiceInvoice

	private req = new UpdateInvoicePayload(
		{
			serviceInvoiceId: this.invoice.id,
			companyId: this.$wss.getCurrent.id
		}
	)

	@Prop({default: 'EMPLOYER'})
	private side!: ServiceInvoiceSide

	private formatDate(date: Date | string): string {
		return moment(date).format("MM.DD.YYYY");
	}

	private voidInvoice() {
		Application.startLoading()
		this.req.status = 'VOIDED'
		ServiceInvoiceOrchestrator.changeStatus(this.req).then(
			() => {
				this.$emit("updated")
			},
			err => processError(err, this)
		).finally(() => Application.stopLoading())
	}

	private completeInvoice() {
		Application.startLoading()
		this.req.status = 'COMPLETED'
		ServiceInvoiceOrchestrator.changeStatus(this.req).then(
			() => {
				this.$emit("updated")
			},
			err => processError(err, this)
		).finally(() => Application.stopLoading())
	}

}
